import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './pedidoLojaForm';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista, getListaLoja, getListaCategoria, getListaProdutoModelo } from './pedidoActions';
import DateFormat from '../common/dateFormat/DateFormat';

class PedidoLoja extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaLoja();
		this.props.getListaCategoria();
		this.props.getListaProdutoModelo();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<>
				<ContentCard>
					<ContentCardHeader>
						<Row>
							{this.props.usuarioCarregado && 
							<Grid cols='12 12 4 2'>
								<Button
									text='Novo Pedido'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastro', { id_loja: (this.props.usuarioCarregado.lojas || []).length == 1 ? (this.props.usuarioCarregado.lojas || [])[0].id : null });
										this.props.initForm({ id_loja: (this.props.usuarioCarregado.lojas || []).length == 1 ? (this.props.usuarioCarregado.lojas || [])[0].id : null, lojaFixa: (this.props.usuarioCarregado.lojas || []).length == 1 ? (this.props.usuarioCarregado.lojas || [])[0].id : null });
									}} />
							</Grid>}
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<h6 style={{ fontSize: 14 }}>Histórico de Pedidos</h6>
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>


				{this.props.lista.filter(item => {
					if (!this.props.usuarioCarregado || (
						(this.props.usuarioCarregado.lojas || []).length &&
						!(this.props.usuarioCarregado.lojas || []).filter(loja => loja.id == item.id_loja).length
					)) {
						return false;
					}
					return true;
				}).map(item => (
					<ContentCard key={item.id}
						onClick={() => {							
							this.props.setModoTela('cadastro', {
								...item
							});
							this.props.initForm({
								...item
							});
						}} >
						<ContentCardBody>											
							<Row>
								<Grid cols='12 12 12 12'>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 12, width: '40%', fontWeight: 'bold' }}>Abertura</div>
										<div style={{ fontSize: 12, width: '30%', fontWeight: 'bold' }}>Loja</div>
										<div style={{ fontSize: 12, width: '30%', textAlign: 'right', fontWeight: 'bold' }}>Usuário</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 12, width: '40%' }}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_pedido)}</div>
										<div style={{ fontSize: 12, width: '30%' }}>{item.nome_loja}</div>
										<div style={{ fontSize: 12, width: '30%', textAlign: 'right' }}>{item.nome_inclusao}</div>
									</div>
								</Grid>
							</Row>
							<Row>
								<Grid cols='12 12 12 12'>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 12, fontWeight: 'bold', width: '40%' }}>Itens</div>
										<div style={{ fontSize: 12, fontWeight: 'bold', width: '30%' }}>Local do Estoque</div>
										<div style={{ fontSize: 12, fontWeight: 'bold', width: '30%', textAlign: 'right' }}>Status</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 12, width: '40%' }}>{item.quantidade || 0}</div>
										<div style={{ fontSize: 12, width: '30%' }}>{item.nome_local_estoque}</div>
										<div style={{ fontSize: 12, width: '30%', textAlign: 'right' }}>{item.datahora_cancelamento ? 'Cancelado' : (item.datahora_confirmacao ? 'Confirmado' : 'Aberto')}</div>
									</div>
								</Grid>
							</Row>
							{item.datahora_cancelamento &&
							<Row>
								<Grid cols='12 12 12 12'>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 12, fontWeight: 'bold' }}>Cancelamento</div>
										<div style={{ fontSize: 12, fontWeight: 'bold' }}>Usuário</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 12 }}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_cancelamento)}</div>
										<div style={{ fontSize: 12 }}>{item.nome_cancelamento}</div>
									</div>
								</Grid>
							</Row>}
							{item.datahora_confirmacao &&
							<Row>
								<Grid cols='12 12 12 12'>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 12, fontWeight: 'bold' }}>Confirmação</div>
										<div style={{ fontSize: 12, fontWeight: 'bold' }}>Usuário</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<div style={{ fontSize: 12 }}>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_confirmacao)}</div>
										<div style={{ fontSize: 12 }}>{item.nome_confirmacao}</div>
									</div>
								</Grid>
							</Row>}
						</ContentCardBody>
					</ContentCard>
				))}
			</>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.pedido.modoTela,
	lista: state.pedido.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaLoja, getListaCategoria, getListaProdutoModelo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PedidoLoja);
